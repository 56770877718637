* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-background: #1f1f38;
  --color-background-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--color-background);
  color: var(--color-white);
  line-height: 1.7;
}