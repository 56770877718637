.card_container {
    width: 40%;
    margin: auto;
    background-color: var(--color-primary);
    border-radius: 2rem;
}

.card_header{
    background: var(--color-background-variant);
    padding: 2.4rem 5rem; /*top/bottom und left/right*/
    border-radius: 2rem 2rem;
    margin-top: 20%;
    text-align: center;
}

.temp{
    padding: 1rem 5rem; /*top/bottom und left/right*/
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 18px;
}

.sunrise-sunset{
    margin: 1rem 5rem; /*top/bottom und left/right*/
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 16px;
}

.flex{
    display: flex;
    justify-content: space-between;
}

.important{
    padding: 1rem 5rem; /*top/bottom und left/right*/
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.humidity {
    padding: 1rem 5rem; /*top/bottom und left/right*/
    color: whitesmoke;
    font-family: 'Recursive', sans-serif;
    font-size: 16px;
}